import React from 'react';
import { NavBar } from '../Navbar';

interface DataProps {
  siteTitle: string;
}

export const Header: React.FC<DataProps> = ({ siteTitle }) => (
  <header className="bg-indigo-100 z-10 relative">
    <div className="container">
      <NavBar />
    </div>
  </header>
);
