import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

export const NavBar: React.FC = () => {
  const [showNav, setShowNav] = useState<boolean>(false);

  const toggle = () => {
    setShowNav(!showNav);
  };

  const links = [
    {
      link: '/aanmelden',
      text: 'Aanmelden',
      class: 'link-btn',
    },
    {
      link: '/programma',
      text: 'Programma',
    },
    {
      link: '/nieuws',
      text: 'Nieuws',
    },
    {
      link: '/supporters',
      text: 'Supporters',
    },
    {
      link: '/maak-het-mee',
      text: 'Maak het mee',
    },
  ];

  // const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef, toggle);

  return (
    <nav className="flex items-center justify-between flex-wrap py-6">
      <div className="flex items-center flex-shrink-0 text-black mr-6">
        {/* <svg
          className="fill-current h-8 w-8 mr-2"
          width="54"
          height="54"
          viewBox="0 0 54 54"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
        </svg> */}
        <Link to="/">
          <span className="font-semibold text-xl tracking-tight">Haagse Makers Week</span>
        </Link>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={toggle}
          className={
            'flex items-center px-3 py-2 border rounded text-black border-black hover:text-black hover:border-black'
          }
        >
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div
        // ref={wrapperRef}
        className={clsx({ hidden: !showNav, 'w-full block flex-grow lg:flex lg:items-right lg:w-auto': true })}
      >
        {links.map((item, index) => (
          <div className="text-sm" key={index}>
            <Link
              to={item.link}
              className="block mt-4 lg:inline-block lg:mt-0 underline uppercase text-black tracking-wider hover:text-pink-600 mr-4"
              activeClassName="block mt-4 lg:inline-block lg:mt-0 text-pink-600 hover:text-pink-600 mr-4 "
            >
              {item.text}
            </Link>
          </div>
        ))}
      </div>
    </nav>
  );
};
