import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Header } from '../Header';
import { Footer } from '../Footer';

import './layout.css';

interface DataProps {
  padded?: boolean;
}

export const Layout: React.FC<DataProps> = ({ children, padded }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="site-wrapper">
      <Header siteTitle={String(data.site.siteMetadata.title)} />
      <div id="main" className="z-10 relative">
        <main className="bg-indigo-100">{children}</main>
      </div>
      <Footer />
    </div>
  );
};
