import React from 'react';
import { FaTwitter, FaInstagram, FaFacebook, FaDiscourse } from 'react-icons/fa';
import { Link } from 'gatsby';
// import { CallForParticipants } from './call-for-participants';

export const Footer: React.FC = () => (
  <footer className="sticky min-h-screen bottom-0 bg-gray-800">
    <div className="container">
      <div className="text-white">23 - 29 november 2020</div>
      <div className="text-white mt-4">Haagse Makers Week</div>

      <div className="mt-8">
        <h3 className="font-bold text-white">Volg Haagse Makers</h3>
        <a
          href="https://twitter.com/haagsemakers"
          target="_blank"
          rel="noreferrer"
          className="inline-block text-white m-2"
        >
          <FaTwitter size="1.5rem" />
        </a>
        <a
          href="https://instagram.com/haagsemakers"
          target="_blank"
          rel="noreferrer"
          className="inline-block text-white m-2"
        >
          <FaInstagram size="1.5rem" />
        </a>
        <a
          href="https://facebook.com/haagsemakers"
          target="_blank"
          rel="noreferrer"
          className="inline-block text-white m-2"
        >
          <FaFacebook size="1.5rem" />
        </a>
        <a
          href="https://community.haagsemakers.nl"
          target="_blank"
          rel="noreferrer"
          className="inline-block text-white m-2"
        >
          <FaDiscourse size="1.5rem" />
        </a>
      </div>

      <div className="py-4 text-white">
        Email{' '}
        <a href="mailto:hoemaaktuhet@haagsemakers.nl" className="underline">
          hoemaaktuhet@haagsemakers.nl
        </a>{' '}
        to sponsor, volunteer, or host an event.
      </div>
      <div className="my-4">
        <ul className="text-white underline">
          <li>
            <Link to="/about#contact" className="py-2 block">
              Contact
            </Link>
          </li>
          <li>
            <Link to="/about#pers" className="py-2 block">
              Pers
            </Link>
          </li>
          <li>
            <a href="https://haagsemakers.nl/privacy" target="_blank" className="py-2 block">
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </div>

    <section className="">
      <div className="container">
        <p className="text-white">
          &copy; 2020 <a href="https://haagsemakers.nl">Platform Haagse Makers</a>
        </p>
      </div>
    </section>
  </footer>
);
